import React, { useState, useEffect } from "react";
import * as Styled from "./style";
import { useAppContext } from "../../context/AppContext";

// Utility function to check if a date is within the last 7 days
const isWithinLastWeek = (date) => {
  const currentDate = new Date();
  const notificationDate = new Date(date);
  const diffInDays = (currentDate - notificationDate) / (1000 * 60 * 60 * 24);
  return diffInDays <= 7;
};

const Notification = () => {
  const [notifications, setNotifications] = useState([]);
  const [context, setContext] = useAppContext();

  // Function to initialize WebSocket connection
  useEffect(() => {
    const webSocketUrl = process.env.NODE_ENV === 'production'
  ? "wss://https://api.galambo.com/" // replace with your production WebSocket URL
  : "ws://localhost:4000";
    // Initialize the WebSocket connection with user ID
    const socket = new WebSocket(webSocketUrl);

    socket.onopen = () => {
      console.log("WebSocket connection opened.");
      const userId = context.auth.user.id;  // Replace with actual user ID
      socket.send(JSON.stringify({ type: 'init', userId }));
    };

    socket.onmessage = (event) => {
      console.log("Received WebSocket message:", event.data);
      const data = JSON.parse(event.data);

      if (data.type === 'message') {
        console.log('New message:', data.message);
        // You can update the state with the new message
        setNotifications(prevNotifications => [
          ...prevNotifications,
          { message: data.message, date: new Date().toISOString() } // Add message to state
        ]);
      }

      if (data.type === 'notification') {
        console.log('New notification:', data.message);
      
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket Error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    // Cleanup WebSocket connection when the component unmounts
    return () => {
      socket.close();
    };
  }, []);

  // Filter notifications based on the date
  const newNotifications = notifications.filter((notif) => isWithinLastWeek(notif.date));
  const lastWeekNotifications = notifications.filter((notif) => !isWithinLastWeek(notif.date));

  return (
    <div style={{ display: `flex`, flexDirection: `column`, width: `${100}%` }}>
      <Styled.NotificationTitle>Notifications</Styled.NotificationTitle>
      <Styled.NotificationContainer>
        {notifications.length > 0 ? (
          <>
            {newNotifications.length > 0 && (
              <Styled.NotificationSection>
                <Styled.NotificatinSectionTitle>New</Styled.NotificatinSectionTitle>
                {newNotifications.map((notif, index) => (
                  <Styled.Notification key={index}>
                    <Styled.NotifcationAvatar src="https://via.placeholder.com/40" alt={notif.message} />
                    <Styled.NotificationMessage>
                      <strong>{notif.message}</strong>
                    </Styled.NotificationMessage>
                  </Styled.Notification>
                ))}
              </Styled.NotificationSection>
            )}

            {lastWeekNotifications.length > 0 && (
              <Styled.NotificationSection>
                <Styled.NotificatinSectionTitle>Last Week</Styled.NotificatinSectionTitle>
                {lastWeekNotifications.map((notif, index) => (
                  <Styled.Notification key={index}>
                    <Styled.NotifcationAvatar src="https://via.placeholder.com/40" alt={notif.message} />
                    <Styled.NotificationMessage>
                      <strong>{notif.message}</strong>
                    </Styled.NotificationMessage>
                  </Styled.Notification>
                ))}
              </Styled.NotificationSection>
            )}
          </>
        ) : (
          <Styled.NotificationMessage>
            Currently, you don't have new notifications
          </Styled.NotificationMessage>
        )}
      </Styled.NotificationContainer>
    </div>
  );
};

export default Notification;
