import React from "react";

const ImageSearchResult = ({ data }) => {
  // Access the 'images' data from the prop
  const { images } = data;

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <section>
        <h2 style={{ fontSize: "20px", color: "#333" }}>Related Images</h2>
        {Object.keys(images).map((category, index) => (
          <div key={index} style={{ marginBottom: "20px" }}>
            <h3 style={{ fontSize: "18px", color: "#555" }}>{category}</h3>
            <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
              {Object.keys(images[category]).map((item, idx) => (
                <div
                  key={idx}
                  style={{
                    width: "150px",
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  <a
                    href={images[category][item].content_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={images[category][item].image}
                      alt={item}
                      style={{ width: "100%", borderRadius: "5px" }}
                    />
                  </a>
                  <p
                    style={{
                      fontSize: "14px",
                      color: "#333",
                      marginTop: "5px",
                    }}
                  >
                    {item}
                  </p>
                </div>
              ))}
            </div>
          </div>
        ))}
      </section>
    </div>
  );
};

export default ImageSearchResult;
