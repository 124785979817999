import React from 'react';
import styled from 'styled-components';

// Styled Components
const PDFContainer = styled.div`
    font-family: Arial, sans-serif;
    padding: 1rem;
    background-color: #f9f9f9;
    border-radius: 8px;
`;

const TextExcerpt = styled.div`
    margin-bottom: 2rem;
    color: #333;
    
    p {
        margin: 0.5rem 0;
    }
`;

const KeywordResults = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
`;

const KeywordSection = styled.div`
    flex: 1 1 200px;
   
    border-radius: 8px;
    padding: 1rem;
    
`;

const KeywordTitle = styled.h3`
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    color: #333;
`;

const ImageResults = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
`;

const ImageResult = styled.a`
    text-decoration: none;
    color: #333;
    display: block;
    width: 100%;
    text-align: center;
`;

const ResultImage = styled.img`
    width: 100%;
    border-radius: 4px;
`;

const ResultTitle = styled.p`
    font-size: 0.9rem;
    margin-top: 0.25rem;
`;

const PDFComponent = ({ data }) => {
    const { pdf_summary, images } = data || {};

    return (
        <PDFContainer>
            {/* Display PDF Summary */}
            {pdf_summary && (
                <TextExcerpt>
                    <h2 style={{ fontSize: "20px", color: "#333" }}>Summary</h2>
                    <p>{pdf_summary}</p>
                </TextExcerpt>
            )}
            
            {/* Display Related Images */}
            <h2 style={{ fontSize: "20px", color: "#333" }}>Related Images</h2>
            <KeywordResults>
                {images && Object.keys(images).length > 0 ? (
                    Object.keys(images).map((keyword, index) => (
                        <KeywordSection key={index}>
                            <KeywordTitle>{keyword}</KeywordTitle>
                            <ImageResults>
                                {Object.values(images[keyword]).map((imageData, i) => (
                                    imageData.image ? (
                                        <ImageResult href={imageData.content_link} target="_blank" rel="noopener noreferrer" key={i}>
                                            <ResultImage src={imageData.image} alt={keyword} />
                                            <ResultTitle>{keyword}</ResultTitle>
                                        </ImageResult>
                                    ) : (
                                        <p key={i}>No images available</p>
                                    )
                                ))}
                            </ImageResults>
                        </KeywordSection>
                    ))
                ) : (
                    <p>No keywords available</p>
                )}
            </KeywordResults>
        </PDFContainer>
    );
};

export default PDFComponent;
