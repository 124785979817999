import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import * as Styled from "./style";
import { useLocation, useParams } from "react-router-dom";

import { CiImageOn, CiLight } from "react-icons/ci";
import { IoIosClose } from "react-icons/io";
import search from "../../assets/home/8.png";
import { useNavigate } from "react-router-dom";
import TextSearchResult from "./result";
import ImageSearchResult from "./imageresult";
import PDFSearchResult from "./pdfresult";
import TreeView from "./tree";
import { SearchInput } from "../../components/SearchInput";
import TagSelection from "./tagSelection";
import loadingSVG from "../../assets/loading.gif";

import icon from "../../assets/about/icon.png";
import galamboSearch from "../../assets/Galambo.png";
import backImg from "../../assets/Hide.png";
import { GoClock } from "react-icons/go";
import { useAppContext } from "../../context/AppContext";
import { Helmet } from "react-helmet";
const token = "_____";
export default function Globe() {
  const { query } = useParams();
  const [context] = useAppContext();
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState("");
  const [treeDes, setTreeDes] = useState("");
  const [searchData, setSearchData] = useState({
    type: null, // "text" | "image" | "pdf"
    data: null, // Contains the search result data
  });

  const [searchAry, setSearchAry] = useState([]);
  const [visible, setVisible] = useState(false);
  // const [imageData, setImageData] = useState();
  const location = useLocation();
  const { imageBinary, pdfBinary } = location.state || {};

  const navigate = useNavigate();
  const websocketURL =
    "wss://galamboo-backend-eydl.onrender.com/ws/discover_topics";
  let socket;

  const handleKeyPress = async (event, param) => {
    if (event.key === "Enter") {
      if (context.auth !== null) {
        const data = await axios.post(
          `https://api.galambo.com/manage/addhistory`,
          { id: context.auth.user.id, history: param }
        );
      }
      // navigate(`/search/${param}`);
      searchAry.push(param);
      await setTreeDes(param);
      await setSearchData();
      navigate(`/search/${query + token + param}`);
    }
  };
  const handleSelect = async (result) => {
    if (result) {
      if (context.auth !== null) {
        const data = await axios.post(
          `https://api.galambo.com/manage/addhistory`,
          { id: context.auth.user.id, history: result }
        );
      }
      navigate(`/search/${result}`);
    }
  };
  const handleResetHistory = async (event, item) => {
    event.preventDefault();
    if (searchAry.length > 1) {
      const data = searchAry.filter((index) => index !== item);
      const newSearchKeywords = data.join(token);

      navigate(`/search/${encodeURIComponent(newSearchKeywords)}`);
      // await getResponseData(data);
      // setSearchAry(data);
    }
  };
  const getResponseData = async (result) => {
    setSearchData();
    setLoading(true);

    if (imageBinary) {
      // If imageBinary is available, use the image search endpoint
      const imageFormdata = new FormData();
      imageFormdata.append("file", imageBinary); // Add the binary image data
      try {
        const imgData = await axios.post(
          `https://berrygoodmedia.herokuapp.com/https://ml.galambo.com/search_by_image/`,
          imageFormdata
        );
        setSearchData({ type: "image", data: imgData.data });
      } catch (error) {
        console.error("Image search error:", error);
        setLoading(false);
      }
    } else if (pdfBinary) {
      // If pdfBinary is available, use the PDF search endpoint
      const pdfFormData = new FormData();
      pdfFormData.append("file", pdfBinary); // Add the binary PDF data
      try {
        const pdfData = await axios.post(
          `https://berrygoodmedia.herokuapp.com/https://ml.galambo.com/search_by_pdf/`,
          pdfFormData
        );
        setSearchData({ type: "pdf", data: pdfData.data });
      } catch (error) {
        console.error("PDF search error:", error);
        setLoading(false);
      }
    } else {
      // If neither image nor PDF is available, proceed with a text-based search
      if (result) {
        try {
          const data = await axios.post(
            `https://berrygoodmedia.herokuapp.com/https://ml.galambo.com/api/discover_topics`,
            { topic: result }
          );

          setSearchData({ type: "text", data: data.data }); // Set the text search result
        } catch (error) {
          console.error("Text search error:", error);
          setLoading(false);
        }
      }
    }

    setLoading(false); // Stop loading once the search is complete
  };

  const onCustomize = useCallback(
    async (param) => {
      const ls_query = query + encodeURIComponent(token) + param;
      navigate(`/search/${ls_query}`);
    },
    [query]
  );

  const handleBackBtn = async () => {
    if (context.auth) {
      const data = await axios.post(
        "https://api.galambo.com/manage/gethistory",
        {
          id: context.auth.user.id,
        }
      );
      if (data) {
        // setHistoryData(data.data.data);
        let numbers = 0;
        const history = data.data.data.history;
        history.sort((a, b) => a.date - b.date);
        if (searchAry.length > 1) {
          numbers = searchAry.length - 2;
        } else {
          history.map((item, key) => {
            if (item.keyword === searchAry[0]) {
              numbers = key - 1;
            }
          });
        }
        if ((numbers) => 0) {
          navigate(`/search/${history[numbers].keyword}`);
        }
      }
    }
  };

  // Establish WebSocket connection on component mount
  useEffect(() => {
    socket = new WebSocket(websocketURL);

    socket.onopen = () => {
      console.log("WebSocket connection established");
    };

    socket.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      socket.close();
    };
  }, []);

  const renderSearchResult = () => {
    if (!searchData) return null;

    if (searchData.type === "text") {
      return (
        <TextSearchResult
          tData={searchData.data}
          imgData={searchData.data?.Images}
          onCustomize={onCustomize}
          current={treeDes}
        />
      );
    } else if (searchData.type === "image") {
      return <ImageSearchResult data={searchData.data} />;
    } else if (searchData.type === "pdf") {
      return <PDFSearchResult data={searchData.data} />;
    }

    return null;
  };

  useEffect(() => {
    if (query) {
      setTreeDes(query);
      const ls = query.split(encodeURIComponent(token));
      setSearchAry(ls);
      getResponseData(ls);
    } else if (imageBinary || pdfBinary) {
      const imageFormdata = new FormData();
      const pdfFormData = new FormData();
      // Append the image data to the FormData object under the 'file' field
      // Ensure `imageBinary` is a File or Blob

      imageFormdata.append("file", imageBinary); //
      pdfFormData.append("file", pdfBinary); //
      // If no query in the URL, use the binary data for image/PDF search
      getResponseData(imageFormdata || pdfFormData);
    }
  }, [query, imageBinary, pdfBinary]);

  return (
    <Styled.GlobeContainer>
      <Helmet>
        <title>Search Page Page | Galambo</title>
        <meta name="description" content="Search page of galambo" />
        <link rel="canonical" href="http://www.galambo.com/:query" />
      </Helmet>

      <Styled.MainContainer visible={visible}>
        {searchData && (
          <TreeView
            data={searchData.data}
            current={treeDes}
            visible={visible}
            setVisible={setVisible}
          />
        )} 

        <div>
          {loading ? (
            <Styled.LoadContainer>
              <img itemProp="image" src={loadingSVG} alt="loading" />
            </Styled.LoadContainer>
          ) : (
            <Styled.DataHeader>
              <Styled.SearchInbox>
                <Styled.SearchSection>
                  <SearchInput
                    word={treeDes}
                    setResult={setResult}
                    handleKeyPress={handleKeyPress}
                    handleSelect={handleSelect}
                  />
                  <Styled.IconSection>
                    <Styled.ImageLoader>
                      <CiImageOn size={24} color="#000" />
                    </Styled.ImageLoader>
                    <Styled.HistoryView>
                      <GoClock size={24} color="#000" />
                    </Styled.HistoryView>
                    <Styled.SearchIcon onClick={() => handleSelect(result)}>
                      <img
                        itemProp="image"
                        src={search}
                        alt="search img"
                        width={30}
                      />
                    </Styled.SearchIcon>
                  </Styled.IconSection>
                </Styled.SearchSection>
                <Styled.SearchDiv>
                  <Styled.KeywordTag>
                    {treeDes ? (
                      searchAry.map((item, key) => (
                        <Styled.SearchHistory key={key}>
                          {item}
                          <a
                            rel="noreferrer"
                            href="#"
                            onClick={(e) => handleResetHistory(e, item)}
                          >
                            <IoIosClose color="white" />
                          </a>
                        </Styled.SearchHistory>
                      ))
                    ) : (
                      <TagSelection
                        query={query}
                        getResponseData={getResponseData}
                        setTreeDes={setTreeDes}
                      />
                    )}
                  </Styled.KeywordTag>
                  <Styled.SearchRgihtContainer>
                    <Styled.SearchMaybeTag>
                      Maybe you mean...{" "}
                      <label>
                        {searchAry.map((item, key) => (
                          <span>{item}</span>
                        ))}
                      </label>
                      <img
                        src={galamboSearch}
                        alt="galambo"
                        width={40}
                        height={45}
                      />
                    </Styled.SearchMaybeTag>
                    <Styled.BackBtn onClick={handleBackBtn}>
                      <img
                        src={backImg}
                        alt="back image"
                        width={10}
                        height={18}
                      />
                      Go Back
                    </Styled.BackBtn>
                  </Styled.SearchRgihtContainer>
                </Styled.SearchDiv>
              </Styled.SearchInbox>

              {!loading ? (
                <Styled.DataDescription>
                  <div>
                    <img
                      itemProp="image"
                      src={icon}
                      alt="icon img"
                      width={17}
                      height={18}
                    />
                  </div>
                  {searchData && (

                    <span>
                      {searchData.type === "text" &&
                        searchData.data.description}
                      {searchData.type === "image" &&
                        searchData.data.data.information}
                      {searchData.type === "pdf" &&
                        searchData.data.data.pdf_summary}
                    </span>
                  )}
                </Styled.DataDescription>
              ) : (
                ""
              )}

              {!loading ? (
                <Styled.SearchKeyWordDiv>
                  <Styled.SearchKeyWord>
                    {searchAry.map((item, key) => (
                      <span>{item}</span>
                    ))}
                  </Styled.SearchKeyWord>
                </Styled.SearchKeyWordDiv>
              ) : (
                ""
              )}
            </Styled.DataHeader>
          )}

          {renderSearchResult()}
        </div>
      </Styled.MainContainer>
    </Styled.GlobeContainer>
  );
}
